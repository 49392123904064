$bs-btn-bg-color: #005899;
$bs-btn-hover-color: #fff;


@font-face {
  font-family: Graphik;
  src: url('../public/assets/fonts/GraphikRegular.otf');
  font-weight: 400;
}

@font-face {
  font-family: Graphik;
  src: url('../public/assets/fonts/GraphikMedium.otf');
  font-weight: 500;
}

body{
  font-family: Graphik;
  font-weight: 400;
  font-size: 16px;
  background-color: #fff
}

.row{
  margin-left: -10px;
  margin-right: -10px;

  & > *{
    padding-left: 10px;
    padding-right: 10px;
  }
}

.btn{
  border: 0;

  &:hover{
    outline: 0;
    box-shadow: none;
    background-color: $bs-btn-bg-color;
    color: $bs-btn-hover-color
  }
}

h1,h2,h3,h4{
  font-weight: 400;
}
.textPrimary{
  color: #005899;
}

@media screen and (min-width: 1400px) {
  .container{
    max-width: 1212px;
  }
}
.header{
  padding-top: 20px;
}
.customInvalid{
  font-size: 0.875em;
  color: #dc3545;
}
.section1{
  font-size: 18px;
  padding-top: 75px;
  

  &>.container{
    max-width: 850px;
  }

  .title{
    font-size: 48px;
    margin-bottom: 16px;
  }
}

.section2{
  padding-top: 50px;
  .fomrWrpr{
    .title{
      font-size: 24px;
      font-weight: 500;
      margin-bottom: 32px;
    }

    .labelControl{
      color: #606060;
      font-size: 18px;
      font-weight: 500;
    }

    .formGroup{
      margin-bottom: 34px;
    }

    .formControl{
      border: 0;
      border-bottom: 2px solid #b7b7b7;
      width: 100%;
      height: 37px;
      box-shadow: none;
      padding: 0;
      font-weight: 400;

      &::placeholder{
        color: #DEDEDE;
        font-weight: 400;
      }

      &:hover,
      &:focus{
        box-shadow: none;
        outline: none;
      }
    }

    .terms{
      margin-top: 20px;
      font-size: 12px;
      color: #606060;
      line-height: 1.5;

      b{
        font-weight: 500;
      }
    }
  }
}

.submitBtn{
  background-color: #005899;
  padding: 20px 30px;
  border-radius: 16px;
  color: #fff;
  font-weight: 500;
  letter-spacing: 1px;
  transition: 250ms linear;

  &:hover{
    color: #fff;
    background-color: #0D212F;
  }

  &:disabled{
    background-color: #005899;
    padding: 20px 30px;
    border-radius: 16px;
    color: #fff;
    font-weight: 500;
    letter-spacing: 1px;
    opacity: .75;
    cursor: not-allowed;
  }
}

.section3{

  .swiper{
    padding-bottom: 50px;

    .swiper-pagination-bullet{
      width: 8px;
      height: 8px;
      border-radius: 8px;
      background-color: #383A3B;
      opacity: .2;
      transition: 250ms;

      &-active{
        width: 48px;
        opacity: 1;
      }
    }
  }
  
}


.matterList{
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;

  & > li{
        position: relative;
        margin-top: 20px;
        padding-bottom: 20px;
        margin-left: 70px;

    .master_icon{
      position: absolute;
      top: -6px;
      left: -70px;
      width: 40px;
      height: 40px;
      border-radius: 100%;
      background: #005899;
      display: flex;
      align-items: center;
      padding: 12px;
      justify-content: center;
        // img{
        //   width: 18px;
        // }
    }
  }
}

.section3{
  padding-top: 80px;
  padding-bottom: 40px;
}

.section4{
  padding-top: 48px;
  padding-bottom: 80px;
  .title{
    font-size: 48px;
    margin-bottom: 42px;
  }
}


.footer{
  background-color: #272727;
  padding-top: 22px;
  padding-bottom: 22px;

  // &>.container{
  //   max-width: 1400px;
  // }

  .socialList{
    list-style: none;
    padding-bottom: 0;
    padding-left: 0;
    margin-bottom: 0;

    li{
      padding-left: 10px;
      padding-right: 15px;

      a{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 35px;
        height: 35px;
        border-radius: 50%;
        border: 1px solid #fff;
        transition: 200ms linear;

        &:hover{
          background-color: #005899;
          border-color: #005899;
        }
      }
    }
  }

  .copyRightText{
    font-size: 18px;
    font-weight: 400;
  }
}

.scrollTopBtn{
  background-color: #005899;
  width: 60px;
  height: 60px;
  border-radius: 4px 0px 0px 4px;
  transition: 150ms linear;
  border: 0;
  position: fixed;
  right: 0;
  top: 50%;
  z-index: 10;
  transform: translateX(100%);

  &.active{
    transform: translateX(0);
  }

  &:hover{
    background-color: #0D212F;
  }
}


.section-t-1{

  &>.container{
    padding-top: 80px;
    padding-bottom: 30px;

    font-size: 18px;

    .title{
      font-size: 45px;
    }
  }
  
}


.section-t-2{
  & > .container{
    padding-top: 40px;

    .title{
      .textPrimary{
        font-weight: 500;
      }
    }
  }
}


.section-t-3{

  &>.container{
    padding-top: 40px;
    padding-bottom: 70px;
  }

  .calendarActionContent{
    font-size: 20px;
    font-style: italic;
    line-height: 1.7;
    display: inline-block;
    position: relative;
    transform: translateX(162%);

    .curvedArrow{
      position: absolute;
      top: 116%;
      right: 44%;
    }
  }
}

.reviewSlider{
  padding-left: 18px;
  padding-right: 18px;

  .swiper-button-next::after,
  .swiper-button-prev::after{
      display: none;
  }

  .swiper-button-next,
  .swiper-button-prev{
    background-color: #005899;
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }

  .swiper-button-prev{
    left: 6px;
  }

  .swiper-button-next{
    right: 6px;
  }

  .contentWrapper{
    background-color: #FBFBFB;
    border-radius: 16px;
    padding: 21px 31px;
  }
  .content{
    padding-top: 6px;
    font-size: 12px;
    color: #606060;
    background-image: url('../public/assets/images/quoteSign.png');
    background-repeat: no-repeat;
    background-position: top left;

    & > p{
      margin-bottom: 0;
    }
  }


  .authorWrpr{
    margin-bottom: 9px;
    .name{
      font-size: 13px;
      margin-bottom: -5px;
    }
  }
}


@media screen and (max-width: 768px) {
  .scrollTopBtn{
    width: 48px;
    height: 48px;
  }

  .section1 .title,
  .section4 .title,
  .section-t-1 > .container .title{
    font-size: 36px;
  }
}

@media screen and (max-width: 767px) {
  .section-t-3 .calendarActionContent{
    transform: translateX(0);
  }
}

/***chrome form autofill background issue fix***/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s;
    -webkit-text-fill-color: #000 !important;
}

.reviewSlider .swiper-button-prev::before {
  content: url('../public/assets/images/left-arrow.png');
  width: 100%;
  height: 100%;
  left: 50%;
  top: 60%;
  transform: translate(-50%, -50%);
  position: absolute;
  z-index: 99;
}
.reviewSlider .swiper-button-next::before {
  content: url('../public/assets/images/right-arr.png');
  width: 100%;
  height: 100%;
  left: 50%;
  top: 60%;
  transform: translate(-50%, -50%);
  position: absolute;
  z-index: 99;
}